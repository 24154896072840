<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.edit_company") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div v-if="formElements" class="card grid p-4">
      <custom-form
        :formElements="formElements"
        formStyle="inline"
        @submitForm="updateCompany"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomForm from "../components/form/CustomForm.vue";
import { getISODate } from "../utils/dateUtils";
export default {
  components: { Breadcrumb, CustomForm },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.getters["company/getCompany"]);
    const fivaldiCustomers = computed(
      () => store.state.company.fivaldiCustomers
    );

    const waiting = computed(() => store.state.company.waiting);

    const formElements = ref(null);

    const setFormElements = () => {
      formElements.value = [
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "name",
          label: "name",
          rules: ["isRequired"],
          type: "text",
          value: company.value.name,
          variable: "name",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "datepicker",
          id: "licence-date",
          label: "licence_validity",
          rules: ["isRequired"],
          textVariant: "white",
          value: getISODate(new Date(company.value.licenceValidity)),
          variable: "licenceValidity",
          variant: "indigo",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "email",
          label: "email",
          rules: ["isRequired", "isEmail"],
          type: "text",
          value: company.value.email,
          variable: "email",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "phone",
          label: "phone",
          rules: ["isRequired", "isPhoneNumber"],
          type: "text",
          value: company.value.phone,
          variable: "phone",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "select",
          id: "fivaldi_customer_select",
          label: "fivaldi_customer",
          optionLabel: ["label"],
          options: fivaldiCustomers.value,
          rules: [],
          value: company.value.fivaldiId,
          variable: "fivaldiId",
        },
        {
          class: "col-span-12 text-right",
          element: "button",
          id: "submit-button",
          label: "save",
          textVariant: "white",
          type: "submit",
          variant: "indigo",
        },
      ];
    };

    onMounted(() => {
      if (
        route.params.companyId &&
        (!company.value.id || company.value.id != route.params.companyId)
      ) {
        store.dispatch("company/getCompany", route.params.companyId);
      }
      if (!fivaldiCustomers.value || !fivaldiCustomers.value.length) {
        store.dispatch("company/getFivaldiCustomers");
      } else {
        setFormElements();
      }
    });

    watch(
      () => fivaldiCustomers.value,
      (fivaldiCustomers) => {
        if (fivaldiCustomers.length) {
          setFormElements();
        }
      }
    );

    return {
      formElements,
      t,
      updateCompany: (data) =>
        store.dispatch("company/updateCompany", { id: company.value.id, data }),
      waiting,
    };
  },
};
</script>
